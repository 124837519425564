import { Button, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";

const PendingWorkRequestsList = () => {
    const [workRequests, setWorkRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterText, setFilterText] = useState('');
    const fetchWithAuth = useFetchWithAuth();
    const navigate = useNavigate();

    const fetchWorkRequests = useCallback(async () => {
        try {
            const data = await fetchWithAuth("/api/work-requests");
            setWorkRequests(data);
            setLoading(false);
        } catch (error) {
            setError(
                error.response
                    ? `Failed to fetch work requests. Status: ${error.response.status}`
                    : error.request
                    ? "Network error. Please check your connection and try again."
                    : "An unexpected error occurred. Please try again later."
            );
            setLoading(false);
        }
    }, [fetchWithAuth]);

    useEffect(() => {
        fetchWorkRequests();
    }, [fetchWorkRequests, navigate]);

    const columns = [
        {
            name: 'Office',
            selector: row => row.office.office_name,
            sortable: true,
        },
        {
            name: 'Title',
            selector: row => row.request_title,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => row.request_description,
            sortable: true,
            cell: row => `${row.request_description.substring(0, 50)}...`
        },
        {
            name: 'Status',
            selector: row => row.request_status,
            sortable: true,
        },
        {
            name: 'Priority',
            selector: row => row.priority_level,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <div className="flex gap-2">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ width: 100 }} // Fixed width for the button
                        onClick={() => navigate(`/work-request-details/${row.uuid}`)}
                    >
                        Details
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{ width: 100 }} // Fixed width for the button
                        onClick={() => navigate(`/work-request-chat/${row.uuid}`)}
                    >
                        Chat
                    </Button>
                </div>
            ),
            ignoreRowClick: true,
            button: true,
        },

    ];

    const filteredItems = workRequests.filter(item => {
        return (
            item.office.office_name.toLowerCase().includes(filterText.toLowerCase()) ||
            item.request_title.toLowerCase().includes(filterText.toLowerCase()) ||
            item.request_description.toLowerCase().includes(filterText.toLowerCase()) ||
            item.request_status.toLowerCase().includes(filterText.toLowerCase()) ||
            item.priority_level.toLowerCase().includes(filterText.toLowerCase())
        );
    });

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <TextField
                variant="outlined"
                size="small"
                placeholder="Search..."
                fullWidth
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
            />
        );
    }, [filterText]);

    if (loading) return <p className="text-center text-gray-600">Loading...</p>;
    if (error) return <p className="text-red-500 text-center">{error}</p>;

    return (
        <div className="p-6 max-w-7xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Pending Work Requests List</h2>
            <div className="bg-white shadow-md rounded-lg p-6">
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    highlightOnHover
                    striped
                    responsive
                    subHeaderAlign="left"
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                    fixedHeader
                    fixedHeaderScrollHeight="calc(100vh - 300px)"
                />
            </div>
        </div>
    );
};

export default PendingWorkRequestsList;
