import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import MediaDisplay from './MediaDisplay';
import RequestMediaDisplay from "./RequestMedia";
import "./WorkRequestDetails.css";

const WorkRequestDetails = () => {
  const [workRequest, setWorkRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [localComments, setLocalComments] = useState({});
  const [serviceProviderCategories, setServiceProviderCategories] = useState([]);
  const [filteredServiceProviders, setFilteredServiceProviders] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const { uuid } = useParams();
  const navigate = useNavigate();
  const fetchWithAuth = useFetchWithAuth();

  const fetchServiceProviders = useCallback(async (categoryName = null) => {
    try {
      let url = `/api/service-providers/`;
      if (categoryName) {
        url += `?category_name=${encodeURIComponent(categoryName)}`;
      }
      const data = await fetchWithAuth(url);
      setServiceProviders(data);
      setFilteredServiceProviders(data);
    } catch (error) {
      setError("Failed to fetch service providers.");
      console.error("Error fetching service providers:", error);
    }
  }, [fetchWithAuth]);

  const fetchWorkRequest = useCallback(async () => {
    try {
      const workRequestData = await fetchWithAuth(`/api/work-request/${uuid}`);
      setWorkRequest(workRequestData);
      setLoading(false);
      
      const categoryName = workRequestData?.work_category?.category_name;
      if (categoryName) {
        await fetchServiceProviders(categoryName);
      } else {
        await fetchServiceProviders();
      }
    } catch (error) {
      setError("Failed to fetch work request.");
      console.error("Error fetching work request:", error);
      setLoading(false);
    }
  }, [uuid, fetchWithAuth, fetchServiceProviders]);

  // Fetch data on initial render
  useEffect(() => {
    fetchWorkRequest();
  }, [fetchWorkRequest]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    if (e.target.value) {
      setFilteredServiceProviders(
        serviceProviders.filter(provider =>
          provider.service_provider_categories.some(
            category => category.id === e.target.value
          )
        )
      );
    } else {
      setFilteredServiceProviders(serviceProviders);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWorkRequest((prevRequest) => ({
      ...prevRequest,
      [name]: value,
    }));
  };

  const handleApiError = async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        try {
          const newToken = await refreshToken();
          fetchWorkRequest();
          fetchServiceProviders();
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("user");

          navigate("/login");
        }
      } else {
        setError(`Failed to fetch work requests. Status: ${error.response.status}`);
      }
    } else if (error.request) {
      setError("Network error. Please check your connection and try again.");
    } else {
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        throw new Error("Refresh token not available");
      }
      const response = await fetchWithAuth("/token/refresh/", {
        method: "POST",
        body: JSON.stringify({ refresh: refreshToken }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { access } = await response.json();
      localStorage.setItem("access_token", access);
      return access;
    } catch (error) {
      throw error;
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setWorkRequest((prevRequest) => ({
  //     ...prevRequest,
  //     [name]: value,
  //   }));
  // };

  const handleDateChange = (date, name) => {
    setWorkRequest((prevRequest) => ({
      ...prevRequest,
      [name]: date ? date.format("YYYY-MM-DD") : "",
    }));
  };

  const handleTimeChange = (time, name) => {
    setWorkRequest((prevRequest) => ({
      ...prevRequest,
      [name]: time ? time.format("HH:mm") : "",
    }));
  };

  const handleCommentChange = (e, commentNumber) => {
    const { value } = e.target;
    setLocalComments((prevComments) => ({
      ...prevComments,
      [`website_coordinator_comment_${commentNumber}`]: value,
    }));
  };

const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Merge local comments with the workRequest object before sending it
    const updatedWorkRequest = { ...workRequest, ...localComments };

    console.log(updatedWorkRequest, "ppopopopoopopopoppppppppp");

    // Automatically set the request status based on the approval status
    if (updatedWorkRequest.office_coordinator_approval_status === "Approved") {
        updatedWorkRequest.request_status = "In Progress";
    }

    try {
        await fetchWithAuth(`/api/work-request/${uuid}/`, {
            method: "PATCH", // Update the work request using PATCH
            data: updatedWorkRequest, // Send the updated work request object as JSON
            headers: {
                "Content-Type": "application/json", // Set the content type
            },
        });
        alert("Work request updated successfully"); // Alert the user on success
        navigate("/website-coordinator/pending-requests/"); // Navigate to the pending requests page
    } catch (error) {
        handleApiError(error); // Handle any errors that occur during the API call
    }
};



  const handleSendMessage = async (e) => {
    e.preventDefault();
    const updatedWorkRequest = { ...workRequest, ...localComments };
    try {
      await fetchWithAuth(`/api/work-request/${uuid}/`, {
        method: "PATCH",
        body: JSON.stringify(updatedWorkRequest),
        headers: {
          "Content-Type": "application/json",
        },
      });
      setWorkRequest((prevRequest) => ({
        ...prevRequest,
        ...localComments,
      }));
      setLocalComments({});
    } catch (error) {
      handleApiError(error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }
  return (
    <Container maxWidth="md" className="container">


      <Box mt={4}>
        {workRequest?.client?.company_name && workRequest?.client?.contact_person && workRequest?.client?.contact_number && (
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Client Details
              </Typography>
              <Typography variant="body1">
                <strong>Company Name:</strong> {workRequest.client.company_name}
              </Typography>
              <Typography variant="body1">
                <strong>Contact Person:</strong> {workRequest.client.contact_person}
              </Typography>
              <Typography variant="body1">
                <strong>Contact Number:</strong> {workRequest.client.contact_number}
              </Typography>
              <Typography variant="body1">
                <strong>Office Name:</strong> {workRequest.office.office_name}
              </Typography>
              <Typography variant="body1">
                <strong>Office Address:</strong> {workRequest.office.address}, {workRequest.office.city}, {workRequest?.office?.postalcode}, {workRequest.office.state}
              </Typography>
              <Typography variant="body1">
                <strong>Office Contact Number:</strong> {workRequest.office.contact_number}
              </Typography>
              <Typography variant="body1">
                <strong>Work Category</strong> - {workRequest.work_category.category_name}
              </Typography>
               <Typography variant="body1">
                <strong>Request Status</strong> - {workRequest.request_status || ""}
              </Typography>
              
            </CardContent>
          </Card>
        )}
      </Box>
      <Box mt={4} mb={4}>
        <Typography variant="h4" gutterBottom>
          Edit Work Request
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Request Title"
                name="request_title"
                variant="outlined"
                fullWidth
                value={workRequest?.request_title || ""}
                onChange={handleInputChange}
                InputProps={{ readOnly: true }}

              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="request_description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={workRequest?.request_description || ""}
                onChange={handleInputChange}
                InputProps={{ readOnly: true }}

              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Appproval Status</InputLabel>
              <Select
                name="office_coordinator_approval_status"
                value={workRequest?.office_coordinator_approval_status || ""}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </Select>
            </Grid>
    
            <Grid item xs={12}>
              <InputLabel>Priority</InputLabel>
              <Select
                name="priority_level"
                value={workRequest?.priority_level || ""}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Preferred Service Date"
                  value={dayjs(workRequest?.preferred_service_date)} // Convert to dayjs object
                  onChange={(date) => handleDateChange(date, "preferred_service_date")}
                  format="DD/MM/YYYY" // Format date here
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  readOnly

                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Preferred Service Time"
                name="preferred_service_time"
                variant="outlined"
                fullWidth
                value={workRequest?.preferred_service_time || ""}
                InputProps={{ readOnly: true }}
                readOnly
              />
            </Grid>


            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date Assigned to Service Provider"
                    value={dayjs(workRequest?.date_assigned_to_service_provider)}
                    onChange={(date) => handleDateChange(date, "date_assigned_to_service_provider")}
                    format="DD/MM/YYYY" // Format added here
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Time Assigned to Service Provider"
                  value={workRequest?.time_assigned_to_service_provider ? dayjs(workRequest.time_assigned_to_service_provider, 'HH:mm') : null}
                  onChange={(time) => handleTimeChange(time, "time_assigned_to_service_provider")}
                  format="HH:mm" // Use 24-hour format
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Expected Completion Date"
                  value={dayjs(workRequest?.expected_completion_date)}
                  onChange={(date) => handleDateChange(date, "expected_completion_date")}
                  format="DD/MM/YYYY" // Format added here
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Estimated Cost"
                name="estimated_cost"
                type="number"
                variant="outlined"
                fullWidth
                value={workRequest?.estimated_cost || ""}
                onChange={handleInputChange}

              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <TextField
                label="Final Cost"
                name="final_cost"
                type="number"
                variant="outlined"
                fullWidth
                value={workRequest?.final_cost || ""}
                onChange={handleInputChange}
              /> */}
            </Grid>


            <Grid item xs={12}>
              <InputLabel>Assigned Service Provider</InputLabel>
              <Select
                name="assigned_service_provider"
                value={workRequest?.assigned_service_provider || ""}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="">Select Service Provider</MenuItem>
                {filteredServiceProviders.map((provider) => (
                  <MenuItem key={provider.id} value={provider.id}>
                    {provider.service_provider.username}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Update Work Request
              </Button>
            </Grid>
          </Grid>
        </form>
        
      <Box mt={4}>
        <MediaDisplay 
          workRequestId={uuid} 
          fetchWithAuth={fetchWithAuth}
        />
      </Box>

        <Box mt={4}>
          <RequestMediaDisplay 
            workRequestId={uuid} 
            fetchWithAuth={fetchWithAuth}
          />
        </Box>

      </Box>
    </Container>
  );
};

export default WorkRequestDetails;
