import { Alert, Button } from '@mui/material';
import React, { useState } from 'react';
import useFetchWithAuth from '../hooks/useFetchWithAuth';


const MaintenanceTask = ({ task, serviceProviders, onSubmitSuccess }) => {
  const [taskData, setTaskData] = useState({
    scheduled_date: task.scheduled_date || '',
    scheduled_time: task.scheduled_time || '',
    service_provider: task.service_provider || '',
    status: task.status || ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const fetchWithAuth = useFetchWithAuth(); 

  const handleChange = (field, value) => {
    setTaskData(prev => ({
      ...prev,
      [field]: value
    }));
    setError(null);
    setSuccessMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);
    
    try {
      const response = await fetchWithAuth(`/api/maintenance-task/${task.uuid}/update-website`, {
        method: 'PATCH',
        data: taskData,
      });

      if (response && !response.error) {
        setSuccessMessage(`Task ${task.category_name} updated successfully`);
        onSubmitSuccess && onSubmitSuccess();
      } else {
        throw new Error(response?.message || response?.error || 'Failed to update task');
      }
    } catch (err) {
      setError(err.message || 'An error occurred while updating the task. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="relative bg-white rounded-xl shadow-md overflow-hidden mb-6">
      <form onSubmit={handleSubmit} className="p-6">
        <div className="sticky top-0 bg-gradient-to-r from-blue-50 to-blue-100 -mx-6 -mt-6 p-6 mb-6 border-b border-blue-200">
          <h3 className="text-xl font-semibold text-gray-800">
            {task.category_name}
          </h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Scheduled Date
            </label>
            <input
              type="date"
              value={taskData.scheduled_date}
              onChange={(e) => handleChange('scheduled_date', e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-blue-400 transition-all"
              disabled={submitting}
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Scheduled Time
            </label>
            <input
              type="time"
              value={taskData.scheduled_time}
              onChange={(e) => handleChange('scheduled_time', e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-blue-400 transition-all"
              disabled={submitting}
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Service Provider
            </label>
            <select
              value={taskData.service_provider}
              onChange={(e) => handleChange('service_provider', e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-blue-400 transition-all"
              disabled={submitting}
            >
              <option value="">Select a service provider</option>
              {serviceProviders.map((provider) => (
                <option key={provider.id} value={provider.id}>
                  {provider.service_provider.username} ({provider.service_provider.email})
                </option>
              ))}
            </select>
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Status
            </label>
            <select
              value={taskData.status}
              onChange={(e) => handleChange('status', e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-blue-400 transition-all"
              disabled={submitting}
            >
              <option value="">Select status</option>
              <option value="Pending">Pending</option>
              <option value="In Progress">In Progress</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>
        </div>

        {(error || successMessage) && (
          <div className="mt-4">
            {error && <Alert severity="error" className="rounded-lg">{error}</Alert>}
            {successMessage && <Alert severity="success" className="rounded-lg">{successMessage}</Alert>}
          </div>
        )}

        <div className="pt-6 border-t border-gray-200 mt-6">
          <Button 
            type="submit" 
            variant="contained"
            color="primary"
            fullWidth
            disabled={submitting}
            className="h-12 bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 transition-all rounded-lg"
          >
            {submitting ? 'Updating...' : `Update ${task.category_name} Task`}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MaintenanceTask;