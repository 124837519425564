import CloseIcon from '@mui/icons-material/Close';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import useFetchWithAuth from "../hooks/useFetchWithAuth";

const MediaDisplay = ({ workRequestId }) => {
  const fetchWithAuth = useFetchWithAuth(); // Use the custom hook
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);

  const fetchMedia = useCallback(async () => {
    if (!workRequestId) {
      setError('Work request ID is required');
      setLoading(false);
      return;
    }

    try {
      const response = await fetchWithAuth(
        `/api/request-media/?work_request_uuid=${workRequestId}`
      );

      setMedia(response || []);
      setError(null);
    } catch (error) {
      setError(
        error.response
          ? `Failed to fetch media. Status: ${error.response.status}`
          : error.request
          ? 'Network error. Please check your connection and try again.'
          : 'An unexpected error occurred. Please try again later.'
      );
    } finally {
      setLoading(false);
    }
  }, [workRequestId, fetchWithAuth]);

  useEffect(() => {
    fetchMedia();
  }, [fetchMedia]);

  const handleOpenModal = useCallback((item) => {
    if (item?.uuid) {
      setSelectedMedia(item);
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedMedia(null);
  }, []);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    } catch (e) {
      return dateString;
    }
  };

  const renderMediaItem = useCallback((item) => {
    if (!item?.media_file || !item?.media_type) {
      return null;
    }

    if (item.media_type === 'Photo') {
      return (
        <img
          src={item.media_file}
          alt="Work request media"
          style={{
            width: '100%',
            height: '200px',
            objectFit: 'cover',
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = 'https://via.placeholder.com/400x200?text=Image+Load+Error';
          }}
        />
      );
    }

    if (item.media_type === 'Video') {
      return (
        <Box sx={{ position: 'relative' }}>
          <video
            src={item.media_file}
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover',
            }}
            onError={(e) => {
              e.target.onerror = null;
              const parent = e.target.parentElement;
              if (parent) {
                parent.innerHTML =
                  '<div style="width: 100%; height: 200px; display: flex; align-items: center; justify-content: center; background: #f0f0f0;">Video Load Error</div>';
              }
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0, 0, 0, 0.3)',
            }}
          >
            <PlayCircleIcon sx={{ fontSize: 60, color: 'white' }} />
          </Box>
        </Box>
      );
    }

    return null;
  }, []);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '1000px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    maxHeight: '90vh',
    overflow: 'auto',
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Media Client
      </Typography>

      {!Array.isArray(media) || media.length === 0 ? (
        <Alert severity="info">Client has not uploaded any media for this work request.</Alert>
      ) : (
        <Grid container spacing={2}>
          {media.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.uuid}>
              <Card
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
                onClick={() => handleOpenModal(item)}
              >
                <CardContent sx={{ p: 0, position: 'relative' }}>
                  {renderMediaItem(item)}
                  <Box sx={{ p: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      {item.media_type} • {formatDate(item.uploaded_at)}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Modal
        open={Boolean(selectedMedia)}
        onClose={handleCloseModal}
        aria-labelledby="media-modal"
      >
        <Box sx={modalStyle}>
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'grey.500',
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.9)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedMedia && (
            <Box sx={{ mt: 2 }}>
              {selectedMedia.media_type === 'Photo' && (
                <img
                  src={selectedMedia.media_file}
                  alt="Full size media"
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '70vh',
                    objectFit: 'contain',
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'https://via.placeholder.com/800x600?text=Image+Load+Error';
                  }}
                />
              )}
              {selectedMedia.media_type === 'Video' && (
                <video
                  src={selectedMedia.media_file}
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '70vh',
                    backgroundColor: '#000',
                  }}
                  controls
                  autoPlay
                  onError={(e) => {
                    e.target.onerror = null;
                    const parent = e.target.parentElement;
                    if (parent) {
                      parent.innerHTML =
                        '<div style="width: 100%; height: 400px; display: flex; align-items: center; justify-content: center; background: #f0f0f0;">Video Load Error</div>';
                    }
                  }}
                />
              )}
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Uploaded on {formatDate(selectedMedia.uploaded_at)}
              </Typography>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default MediaDisplay;
