import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import "./ServiceProviderForm.css";

const ServiceProviderForm = () => {
    const [serviceProviderData, setServiceProviderData] = useState({
        emp_id: "",
        username: "",
        phone_number: "",
        email: "",
        user_category: "service_provider"
    });

    const [serviceProviderCategoryIds, setServiceProviderCategoryIds] = useState([]); // Expecting an array of IDs
    const [serviceProviderCategories, setServiceProviderCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const navigate = useNavigate();
    const fetchWithAuth = useFetchWithAuth();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const data = await fetchWithAuth("/api/service-category-options/", {
                method: "GET",
            });
            setServiceProviderCategories(data);  // Assuming data is an array of categories
        } catch (error) {
            console.error("Error fetching categories:", error);
            setError("Error fetching service provider categories.");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setServiceProviderData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleCategoryChange = (e) => {
        const { options } = e.target;
        const selectedCategories = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedCategories.push(options[i].value);
            }
        }
        setServiceProviderCategoryIds(selectedCategories);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        setSuccess("");

        try {
            await fetchWithAuth("/api/service-providers/", {
                method: "POST",
                 data: {
                    service_provider: serviceProviderData,
                    service_provider_category_ids: serviceProviderCategoryIds
                }
            });
            setSuccess("Service Provider created successfully!");
            navigate("/service-providers");
            resetForm();
        } catch (error) {
            console.error("Error creating Service Provider:", error);
            if (error.response && error.response.data && error.response.data.phone_number) {
                setError(`Phone Number Error: ${error.response.data.phone_number[0]}`);
            } else {
                setError("There was an error creating the Service Provider.");
            }
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setServiceProviderData({
            emp_id: "",
            username: "",
            phone_number: "",
            email: "",
            user_category: "service_provider",
        });
        setServiceProviderCategoryIds([]);
    };

    return (
        <div className="service-provider-form-container">
            <h1 className="form-title">Create Service Provider</h1>
            <form onSubmit={handleSubmit} className="service-provider-form">
                <div className="form-group">
                    <label htmlFor="emp_id">Employee ID:</label>
                    <input
                        type="number"
                        id="emp_id"
                        name="emp_id"
                        value={serviceProviderData.emp_id}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={serviceProviderData.username}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone_number">Phone Number:</label>
                    <input
                        type="text"
                        id="phone_number"
                        name="phone_number"
                        value={serviceProviderData.phone_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={serviceProviderData.email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="category">Service Provider Categories:</label>
                        <select
                            id="category"
                            multiple
                            value={serviceProviderCategoryIds}
                            onChange={handleCategoryChange}
                            required
                        >
                            <option value="">Select categories</option>
                            {serviceProviderCategories.map((category) => (
                                <option
                                    key={category.service_provider_category_id}
                                    value={category.service_provider_category_id}
                                >
                                    {category.category_name}
                                </option>
                            ))}
                        </select>
                </div>
                <button type="submit" disabled={loading} className="submit-button">
                    {loading ? "Creating..." : "Create Service Provider"}
                </button>
            </form>
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
        </div>
    );
};

export default ServiceProviderForm;
