import { Button, CircularProgress, FormControl, MenuItem, Select, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchWithAuth from '../hooks/useFetchWithAuth';
import MaintenanceTask from './MaintenanceTask';

const MaintenanceRequestDetails = () => {
  const [maintenanceRequest, setMaintenanceRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [status, setStatus] = useState('');  // New state for status
  const [updating, setUpdating] = useState(false);  // New state for update loading
  const { maintenanceId } = useParams();
  const navigate = useNavigate();
  const fetchWithAuth = useFetchWithAuth();

  const fetchMaintenanceRequest = useCallback(async () => {
    try {
      const response = await fetchWithAuth(`/client/maintenance-requests/${maintenanceId}/`);
      if (response.success) {
        setMaintenanceRequest(response.data);
        setStatus(response.data.status); // Set initial status
      } else {
        setError(response.message || 'Failed to fetch maintenance request');
      }
    } catch (error) {
      console.error('Failed to fetch maintenance request:', error);
      setError('An error occurred while fetching the maintenance request.');
    } finally {
      setLoading(false);
    }
  }, [maintenanceId, fetchWithAuth]);

  const fetchServiceProviders = useCallback(async () => {
    try {
      const data = await fetchWithAuth(`/api/service-providers/`);
      setServiceProviders(data);
    } catch (error) {
      console.error('Failed to fetch service providers:', error);
    }
  }, [fetchWithAuth]);

  useEffect(() => {
    fetchServiceProviders();
    fetchMaintenanceRequest();
  }, [fetchServiceProviders, fetchMaintenanceRequest]);

  // New function to handle status update
  const handleStatusUpdate = async () => {
    setUpdating(true);
    try {
      const response = await fetchWithAuth(`/client/maintenance-requests/${maintenanceId}/`, {
        method: 'PATCH',
        data: { 
          status: status,
        },
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.success) {
        await fetchMaintenanceRequest(); // Refresh data after update
      } else {
        setError(response.message || 'Failed to update maintenance request status');
      }
    } catch (error) {
      console.error('Failed to update status:', error);
      setError('An error occurred while updating the status.');
    } finally {
      setUpdating(false);
    }
  };

  const handleTaskSubmitSuccess = () => {
    fetchMaintenanceRequest();
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-semibold">Maintenance Request Details</h2>
        
        {/* Status Update Section */}
        <div className="flex items-center space-x-4">
          <FormControl size="small">
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="min-w-[150px]"
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
            </Select>
          </FormControl>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleStatusUpdate}
            disabled={updating || status === maintenanceRequest?.status}
          >
            {updating ? <CircularProgress size={24} /> : 'Update Status'}
          </Button>
        </div>
      </div>
      
      <Button variant="outlined" onClick={() => navigate(-1)} className="mb-4">
        Back
      </Button>

      {/* Office and Client Details */}
      <div className="space-y-4 mb-6">
        <div className="bg-gray-100 p-4 rounded-md shadow">
          <h3 className="text-xl font-medium mb-2">Office Details</h3>
          <p><strong>Office Name:</strong> {maintenanceRequest?.office?.office_name || 'N/A'}</p>
          <p><strong>Office Address:</strong> {maintenanceRequest?.office?.address || 'N/A'}, {maintenanceRequest?.office?.city}, {maintenanceRequest?.office?.state}</p>
          <p><strong>Office Contact Number:</strong> {maintenanceRequest?.office?.contact_number || 'N/A'}</p>
        </div>

        <div className="bg-gray-100 p-4 rounded-md shadow">
          <h3 className="text-xl font-medium mb-2">Client Details</h3>
          <p><strong>Requested By:</strong> {maintenanceRequest?.requested_by || 'N/A'}</p>
        </div>

        <div className="bg-gray-100 p-4 rounded-md shadow">
          <h3 className="text-xl font-medium mb-2">Maintenance Details</h3>
          <p><strong>Description:</strong> {maintenanceRequest?.description || 'N/A'}</p>
          <p><strong>Maintenance Date:</strong> {maintenanceRequest?.requested_date || 'N/A'}</p>
          <p><strong>Maintenance Time:</strong> {maintenanceRequest?.requested_time || 'N/A'}</p>
          <p><strong>Current Status:</strong> {maintenanceRequest?.status || 'N/A'}</p>
        </div>
      </div>

      {/* Render Maintenance Tasks */}
      <div className="space-y-6">
        {maintenanceRequest?.maintenance_tasks.map((task) => (
          <MaintenanceTask
            key={task.uuid}
            task={task}
            serviceProviders={serviceProviders}
            onSubmitSuccess={handleTaskSubmitSuccess}
            fetchWithAuth={fetchWithAuth}
          />
        ))}
      </div>
    </div>
  );
};

export default MaintenanceRequestDetails;