import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import "./ServiceProvider.css";

const ServiceProvider = () => {
    const [serviceProviders, setServiceProviders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchWithAuth = useFetchWithAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchServiceProviders = async () => {
            try {
                setIsLoading(true);
                const data = await fetchWithAuth("/api/service-providers/");
                // Formatting the data based on the response structure
                const formattedData = data.map((provider) => ({
                    id: provider.id,
                    username: provider.service_provider?.username || "N/A",
                    email: provider.service_provider?.email || "N/A",
                    emp_id: provider.service_provider?.emp_id || "N/A",
                    phone_number: provider.service_provider?.phone_number || "N/A",
                    secret_key: provider.service_provider?.secret_key || "N/A",
                    // Adding categories as an array to handle multiple categories
                    service_provider_category_names: provider.service_provider_category_names || []
                }));
                setServiceProviders(formattedData);
                setError(null); // Clear any previous errors
            } catch (err) {
                setError(err.message || "Failed to fetch service providers.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchServiceProviders();
    }, [fetchWithAuth]);

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this service provider?");
        if (!confirmDelete) return;

        try {
            await fetchWithAuth(`/api/service-providers/${id}/`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            setServiceProviders((prevProviders) =>
                prevProviders.filter((provider) => provider.id !== id)
            );
        } catch (err) {
            console.error("Failed to delete service provider:", err);
            setError("Failed to delete service provider.");
        }
    };

    if (isLoading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return (
            <div className="error">
                <p>Error: {error}</p>
                <button
                    className="btn btn-secondary"
                    onClick={() => window.location.reload()}
                >
                    Reload Page
                </button>
            </div>
        );
    }

    return (
        <div className="container">
            <h2 className="my-4">Service Provider List</h2>
            <Link to="/create-service-provider" className="btn btn-primary mb-4">
                Create New Service Provider
            </Link>
            <div className="table-responsive">
                <table
                    id="service-providers-table"
                    className="table table-bordered table-striped table-hover"
                >
                    <thead className="thead-dark">
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Employee ID</th>
                            <th>Phone Number</th>
                            <th>Secret Key</th>
                            <th>Categories</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {serviceProviders.length > 0 ? (
                            serviceProviders.map((provider) => (
                                <tr key={provider.id}>
                                    <td>{provider.username}</td>
                                    <td>{provider.email}</td>
                                    <td>{provider.emp_id}</td>
                                    <td>{provider.phone_number}</td>
                                    <td>{provider.secret_key}</td>
                                    <td>
                                        {/* Display categories with commas */}
                                        {provider.service_provider_category_names && provider.service_provider_category_names.length > 0 ? (
                                            provider.service_provider_category_names.join(', ') // Join categories with a comma
                                        ) : (
                                            <span>No Categories</span>
                                        )}
                                    </td>
                                    <td>
                                        <div className="btn-group" role="group">
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => handleDelete(provider.id)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center">
                                    No Service Providers available.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ServiceProvider;
